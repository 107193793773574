import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
// import { Line as LineChart } from 'vue-chartjs';
import { last, times } from 'lodash';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import LazyImage from '@/components/common/lazyImage/LazyImage.vue';
import { State as StateClass } from '@/store/models';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import FundDocs from '@/components/common/fund-docs/FundDocs.vue';
import FormInput from '@/components/common/form-elements/FormInput.vue';
import ProgressBarColor from '@/components/common/progress-bar-color/ProgressBarColor.vue';
import ProgressBarDash from '@/components/common/progress-bar-dash/ProgressBarDash.vue';
import RedeemGiftCodeModal from '@/components/account/single-fund/redeem-gift-code-modal/RedeemGiftCodeModal.vue';
import AccountInvestments from '@/components/account/investments/AccountInvestments.vue';
import AccountTrees from '@/components/account/trees/AccountTrees.vue';
import AccountEarnings from '@/components/account/earnings/AccountEarnings.vue';
import NetValue from '@/components/common/net-value/netValue.vue';
import { collections } from '@/vue';
import { Asset } from '@/store/models/asset';
import { firebase } from '@/firebase';
import { Investment, InvestmentEarning, InvestmentTree, Payment } from '@/store/models/investment';
import { roundNumber } from '@/filters/number';
import { capitalize } from '@/filters/string';
import { treeAgeContent } from '@/lang/tree';
import InvestmentProgressChart from './investment-progress-chart/InvestmentProgressChart.vue';

// Removes the reference type fields from the collection (could be improved to be dynamic e.g. `Collection extends DocumentReference`)
type WithoutRefs<Collection> = Omit<Collection, 'asset' | 'investor' | 'investment'>;

@Component({
  components: {
    WithDownloads,
    FundDocs,
    ProgressBarColor,
    ProgressBarDash,
    InvestmentProgressChart,
    NetValue,
    LazyImage,
    AccountInvestments,
    AccountTrees,
    AccountEarnings,
    FormInput,
    RedeemGiftCodeModal,
  },
})

export default class AccountSingleFund extends Vue {
  @Prop({ default: (): undefined => undefined, required: true }) asset!: Asset;
  @Prop({ default: (): undefined => undefined }) investment!: Investment;

  detailsExpanded = true;

  @State('user') user!: StateClass['user'];

  @Getter getCombinedDividendsFormatByAsset!: Function;
  @Getter hasDifferentPaymentFormatsByAsset!: Function;
  @Getter getInvestmentByAsset!: Function;
  @Getter investmentHasPaidPayments!: Function;
  @Getter investmentHasPaidPaymentsType!: Function;
  @Getter getPaidPaymentsByInvestmentId!: Function;

  detailsActiveTabType = 'progress'
  // showRedeemGiftCodeModal = false
  currentGiftCode = ''
  showRedeemGiftCodeModal = false
  treeAgeDraggedPercentage = null

  showAllDividends = false;
  loadedEarnings = false;

  collections = collections

  earnings: WithoutRefs<InvestmentEarning>[] = [];

  @Watch('user.id', { immediate: true })
  @Watch('investment.id', { immediate: true })
  @Watch('hasPaidInvestments', { immediate: true })
  async onNewHasPaidInvestments(): Promise<void> {
    if (!this.loadedEarnings && this.user?.id && this.investment?.id && this.hasPaidInvestments) {
      const investorRef = this.$firestore.collection('investors').doc(this.user!.id);
      try {
        const querySnapshot = await this.$firestore
          .collection(`investments/${this.investment.id}/earnings`)
          .where('investor', '==', investorRef)
          .where('deleted', '==', false)
          .orderBy('paymentDateTime', 'desc')
          .get() as firebase.firestore.QuerySnapshot<InvestmentEarning>;

        this.$set(this, 'earnings', querySnapshot.docs.map((doc): InvestmentEarning => doc.data()));
        this.loadedEarnings = true;
      } catch (error) {
        console.error(error); // eslint-disable-line
      }
    }
  }

  get getTotalEarnings(): number {
    return this.investment?.totalEuroEarnings || 0;
  }

  get getTotalShares(): number {
    return new BigNumber(this.investment?.boughtSharesTotal || 0).plus(this.investment?.receivedSharesTotal || 0).toNumber();
  }

  get getTotalCompensation(): number {
    return new BigNumber(this.getTotalShares).multipliedBy(this.asset.co2Compensation || 0).toNumber();
  }

  get getRemainingBonds(): number {
    return Math.ceil(9000 / (this.asset.co2Compensation || 0)) - this.getTotalShares;
  }

  get getPaidEurosTotal(): number {
    return (this.investment?.paidEuroTotal || 0);
  }

  get hasPaidInvestments(): boolean {
    return this.investment?.id && this.investmentHasPaidPayments(this.investment.id);
  }

  get hasPaidInvestmentsType(): boolean {
    return this.investment?.id && this.investmentHasPaidPaymentsType(this.investment.id);
  }

  get formattedCounrty(): string {
    let { country } = this.asset;
    // Format Netherlands according to locale
    if (country === 'Netherlands') {
      switch (this.$i18n.locale) {
        case 'en':
          country = 'The Netherlands';
          break;
        case 'nl':
          country = 'Nederland';
          break;
        default:
          break;
      }
    }

    return country;
  }

  expandDetails(): void {
    this.detailsExpanded = !this.detailsExpanded;
  }

  getAmountPaymentsByYear(year: number): number {
    return this.payments
      .filter((payment): boolean => payment.type !== 'gift-purchase' && !!payment.paymentDateTime)
      .filter((payment): boolean => payment.paymentDateTime!.toDate().getFullYear() <= year)
      .map((payment): number => payment.providerData?.metadata?.sharesAmount || 0)
      .reduce((accumulator, a): number => accumulator + a, 0);
  }

  getColors(color: string): string[] {
    const chartLength = this.expectedRoi.length;
    return Array(chartLength).fill(color);
  }

  toggleRedeemGiftCodeModal(): void {
    this.showRedeemGiftCodeModal = !this.showRedeemGiftCodeModal;
  }

  get steps(): any[] {
    const lang: string = this.$i18n.locale as string;
    return treeAgeContent[lang];
  }

  get lastStep(): any {
    return last(this.steps);
  }

  get stepPercentage(): number {
    return new BigNumber(100).dividedBy(this.steps.length).toNumber();
  }

  get chartLabels(): number[] {
    let startYear = this.firstPayment?.paymentDateTime?.toDate().getFullYear() || new Date().getFullYear();
    return times(21, (): number => startYear++);
  }

  get expectedRoi(): number[] {
    if (!this.firstPayment || !this.asset.type) {
      return [];
    }

    const EXPECTED_DATA = {
      tree: [0.0, 0.0, 0.0, 0.16, 0.59, 1.20, 2.47, 2.19, 2.19, 2.19, 2.19, 2.19, 2.19, 2.19, 2.19, 2.19, 2.19, 2.19, 2.19, 2.19, 2.19],
      agroforest: [0.0, 0.0, 0.0, 0.5, 5.7, 6.8, 7.7, 10.5, 6.3, 9.0, 7.1, 9.5, 7.3, 11.9, 6.5, 9.5, 22.2],
    };

    const expected = EXPECTED_DATA[this.asset.type];

    if (!expected) {
      return [];
    }

    const startYear = this.firstPayment?.paymentDateTime?.toDate().getFullYear() || new Date().getFullYear();

    return expected.map((value, index): number => {
      const amountShares = this.getAmountPaymentsByYear(new BigNumber(startYear).plus(index).toNumber());
      return roundNumber(new BigNumber(amountShares).multipliedBy(value).toNumber(), 2);
    });
  }

  get repaymentRoi(): number[] {
    if (!this.firstPayment || !this.asset.type) {
      return [];
    }

    const REPAYMENT_DATA = {
      tree: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.61, 1.61, 1.61, 1.61, 1.61, 1.61, 1.61, 1.61, 1.61, 1.61, 1.61, 1.61, 1.61, 1.61],
      agroforest: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 9.7, 9.7, 9.7, 9.7, 9.7, 9.7, 9.7, 9.7, 9.7, 9.7],
    };

    const repayment = REPAYMENT_DATA[this.asset.type];

    if (!repayment) {
      return [];
    }

    const startYear = this.firstPayment?.paymentDateTime?.toDate().getFullYear() || new Date().getFullYear();

    return repayment.map((value, index): number => {
      const amountShares = this.getAmountPaymentsByYear((new BigNumber(startYear).plus(index)).toNumber());
      return roundNumber(new BigNumber(amountShares).multipliedBy(value).toNumber(), 2);
    });
  }

  get achievedRoi(): number[] {
    const values = this.earnings?.map(({ amount }): number => amount) || [];
    const chartLength = this.expectedRoi.length;
    const remainingValues = Array(chartLength - values.length).fill(0);
    const totalAchievedRoi = values.concat(remainingValues);

    return totalAchievedRoi.map((value, index): number => value);
  }

  get progressChartData(): any {
    return {
      labels: this.chartLabels,
      datasets: [
        {
          label: capitalize(this.$t('common.expectedInterestROI') as string),
          fill: false,
          backgroundColor: this.getColors('#4E5772'),
          data: this.expectedRoi,
        },
        {
          label: capitalize(this.$t('common.expectedRepaymentROI') as string),
          fill: false,
          backgroundColor: this.getColors('#8A94B2'),
          data: this.repaymentRoi,
        },
        {
          label: capitalize(this.$t('common.achievedROI') as string),
          fill: false,
          backgroundColor: this.getColors('#000B2E'),
          data: this.achievedRoi,
        },
      ],
    };
  }

  // The first payment date will be the 3.5 years (42 months) after the date of the first payment
  // TODO: this is now hard-coded for the initial payment, later on the dividend schedule will have to be taken into account
  get expectedNextPayment(): any {
    return this.firstPayment ? moment(this.firstPayment?.paymentDateTime?.toDate()).add(42, 'months').toDate() : null;
  }

  get treeAgeDurationDays(): number {
    return 20 * 365; // Hardcode lifetime to 20 years
    // const assetStartDateYear = this.asset.startDateTime.toDate().getFullYear();
    // const assetEndDateYear = this.asset.endDateTime.toDate().getFullYear();
    // return assetEndDateYear - assetStartDateYear || 20;
  }

  get firstPayment(): Payment {
    return this.payments
      .filter(({ type, providerData, paymentDateTime }): boolean => type !== 'gift-purchase' && !!paymentDateTime)
      .sort((a, b): number => a.paymentDateTime!.toMillis() - b.paymentDateTime!.toMillis())[0];
  }

  /**
   * Calculates the age of the first (oldest) tree bought in years
   */
  get treeAgeCurrentYears(): number {
    if (!this.firstPayment) {
      return 0;
    }

    const yearsPassed = new BigNumber(Date.now()).minus(this.firstPayment?.paymentDateTime?.toMillis() || 0).dividedBy(1000).dividedBy(60)
                        .dividedBy(60)
                        .dividedBy(24)
                        .dividedBy(365)
                        .toNumber();
    return Math.floor(yearsPassed);
  }

  /**
   * Calculates the age of the first (oldest) tree bought in days
   */
  get treeAgeCurrentDays(): number {
    return this.firstPayment?.paymentDateTime ? moment(Date.now()).diff(this.firstPayment?.paymentDateTime?.toDate(), 'days') : 0;
  }

  /**
   * Calculates the amount of days for the progress bar label based on the progress bar value
   */
  get treeAgeDraggedDays(): number {
    if (this.treeAgeDraggedPercentage === null) {
      return 0;
    }

    if (this.treeAgeDraggedPercentage === 0) {
      return 0;
    }

    const minDays = this.treeAgeContent.minDays;
    const maxDays = this.steps[this.treeAgeContent.index + 1]?.minDays || 365 * 20;
    const daysDiff = maxDays - minDays;
    const currentPhasePercentage = ((((this.treeAgeDraggedPercentage || 0) % this.stepPercentage) * 100) / this.stepPercentage) / 100;
    const currentAmountDaysInPhase = Math.ceil(daysDiff * currentPhasePercentage);
    const currentTotalAmountDays = minDays + currentAmountDaysInPhase;

    return currentTotalAmountDays;
  }

  /**
   * Calculates the percentage of the progress bar based on the days the first payment exists
   */
  get treeAgeCurrentPercentage(): number {
    const currentPhase = this.steps
      .map((item, index): any => ({ ...item, index }))
      .filter((content, index): boolean => this.treeAgeCurrentDays >= content.minDays && (this.steps[index + 1] ? this.treeAgeCurrentDays < this.steps[index + 1].minDays : true))[0];

    const minDays = currentPhase.minDays;
    const maxDays = this.steps[currentPhase.index + 1]?.minDays || 365 * 20;
    const daysDiff = maxDays - minDays;
    const amountDaysInCurrentPhase = this.treeAgeCurrentDays - minDays;
    const currentPhasePercentage = (((amountDaysInCurrentPhase * 100) / daysDiff) / 100) * this.stepPercentage;
    const totalPercentage = (currentPhase.index * this.stepPercentage) + currentPhasePercentage;

    return totalPercentage;
  }

  /**
   * Generates a label for the tree age based on amount of days
   */
  get treeAgeCurrentLabel(): string {
    return this.getTreeAgeLabel(this.treeAgeCurrentDays);
  }

  get treeAgeActiveLabel(): string {
    const amountDays = this.treeAgeDraggedPercentage !== null ? this.treeAgeDraggedDays : this.treeAgeCurrentDays;

    return this.getTreeAgeLabel(amountDays);
  }

  get maxInvestmentsReached(): boolean {
    return !!this.investment?.boughtSharesTotal &&
      !!this.asset?.maxSharesPerInvestor &&
      this.investment?.boughtSharesTotal >= this.asset?.maxSharesPerInvestor;
  }

  getTreeAgeLabel(amountDays: number): string {
    let value = amountDays;
    let unit = this.$tc('common.day', value);

    const startDate = moment().subtract(value, 'd');

    if (amountDays > 7) {
      value = moment().diff(startDate, 'weeks');
      unit = this.$tc('common.week', value);
    }

    if (amountDays > 28) {
      value = moment().diff(startDate, 'months');
      unit = this.$tc('common.month', value);
    }

    if (amountDays > 731) {
      value = moment().diff(startDate, 'year');
      unit = this.$tc('common.year', value);

      if (this.treeAgeDraggedPercentage === 100) {
        value = 20;
        unit = this.$tc('common.year', value);
      }
    }

    return `${value} ${unit}`;
  }

  /**
   * Returns all content for the current phase of the tree
   */
  get treeAgeContent(): { title: string, text: string, minDays: number, index: number, imgIndex: number } {
    let currentStepIndex = Math.floor((this.treeAgeDraggedPercentage || this.treeAgeCurrentPercentage) / this.stepPercentage);

    if (currentStepIndex > this.steps.length - 1) {
      currentStepIndex = this.steps.length - 1;
    }

    const content = this.steps[currentStepIndex];

    return {
      ...content,
      imgIndex: currentStepIndex + 1,
      index: currentStepIndex,
    };
  }

  /**
   * Returns the title for the current phase of the tree
   */
  get treeAgeContentTitle(): string {
    return this.treeAgeContent.title || '';
  }

  /**
   * Returns the text for the current phase of the tree
   */
  get treeAgeContentDescription(): string {
    return this.treeAgeContent.text || '';
  }

  /**
   * Returns the image for the current phase of the tree
   */
  get treeAgeContentImage(): string {
    return require(`@/assets/images/treeAge/${this.treeAgeContent.imgIndex}.jpg`) || '';
  }

  get payments(): Payment[] {
    return ([
      ...this.getPaidPaymentsByInvestmentId(this.investment.id),
    ] as Payment[]);
  }
}
